import React from 'react'
import './App.css';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Crusty, CrustyCondensed } from "./writing";

function App() {
  return (
    <Routes>
      <Route path="/" element={<CrustyCondensed />} />
      <Route path="/full" element={<Crusty />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
